import Header from "./common/header/main.es6";
import NewsFeed from "./common/newsfeed/main.es6";
import Anchor from "./common/ui/anchor.es6";
import Accordion from "./common/ui/accordion.es6";
import ScrollAnime from "./common/anime/_scroll.es6";
import MovieModal from "./common/modal/movie.es6";
import BgCanvas from "./common/bg/canvas.es6";
import Switch from "./common/ui/switch.es6";


class Main{

    constructor(){
        this._init();
    }


    _init(){
        window.ADLIVE = {};
        this.header = new Header();
        this.newsfeed = new NewsFeed();
        this.anchor = new Anchor();
        this.accordion = new Accordion();
        this.scroll = new ScrollAnime();
        this.canvas = new BgCanvas();
        this.switch = new Switch();

        if(document.querySelector('.c-ytb')){
            window.ADLIVE.movie = new MovieModal();
        }
        
        this._setView();
    }

    _setView(){
        
        setTimeout(()=>{
            document.body.classList.add('-ready');
        }, 500);
    }
}

const main = new Main();
