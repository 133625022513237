/**
 * @classdesc News ティッカー
 */
import { gsap } from "gsap";


export default class NewsFeed{

    constructor() {
        this._init();
    }

    _init() {
        this.beforeIndex = 0;
        this.currentIndex = 0;
        this.req = 0;
        this.updateCount = 0;
        this.vx = 50;
        this.interval = 350;

        this.items = [...document.querySelectorAll('.l-newsfeed__item')];
        this.$self = document.querySelector('.l-newsfeed__list');

        this._ticker();
    }
    
    _ticker(){
        if(this.items.length <= 0) return;
        this.items[0].classList.add('-active');
        this._update();
    }

    _update(){

        if(this.updateCount < this.interval){
            this.updateCount++;
        }
        else{
            this.currentIndex++;
            if(this.currentIndex > this.items.length - 1) this.currentIndex = 0;

            const $before = this.items[this.beforeIndex];
            $before.classList.remove('-active');

            const $current = this.items[this.currentIndex];
            gsap.set($current, {x:0});
            $current.classList.add('-active');

            this._itemMove($current);

            this.beforeIndex = this.currentIndex;
            this.updateCount = 0;
        }

        this.req = requestAnimationFrame(this._update.bind(this));
    }

    _itemMove($item){
        let textW = $item.getBoundingClientRect().width;
        let wrapperW = this.$self.getBoundingClientRect().width;
        let distance;
        let v_interval = 0;
        
        if(textW > wrapperW){
            distance = textW - wrapperW;
            v_interval = distance / this.vx;
            gsap.to($item, {duration:v_interval, x:-distance, delay:1, ease:'linear'});
        }
    }
}