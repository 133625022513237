import { gsap } from "gsap";


export default class ImageModal{

    constructor(){
        this._init();
    }


	show(youtubeId){
		this.scrolled_y = window.pageYOffset;
        gsap.set(this.$wrap, {position:'fixed', top:-this.scrolled_y});
        gsap.set([document.querySelector('html'),document.querySelector('body')], {scrollTop: 0});

		const tag = `<iframe src="${this.YOUTUBE_URL + youtubeId + this.YOUTUBE_OPTION}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
		this.$movie_target.innerHTML = tag;
		this.$modal.classList.add('-active');
	}


	hide(){
		gsap.set(this.$wrap, {position:'relative', top:0});
		gsap.set([document.querySelector('html'),document.querySelector('body')], {scrollTop: this.scrolled_y});
		this.$movie_target.innerHTML = '';
		this.$modal.classList.remove('-active');
	}

    

    _init(){
		this.YOUTUBE_URL = '//www.youtube.com/embed/';
		this.YOUTUBE_OPTION = '?playsinline=1&autoplay=1&mute=1';

		this.scrolled_y = window.pageYOffset;

		this.$wrap = document.querySelector('.l-wrapper');
		this.$modal = document.querySelector('.c-ytb');
		this.$movie_target = document.querySelector('.js-ytb-content');

		this._ui();
    }


	_ui(){

		[...document.querySelectorAll('.js-ytb-open')].forEach(($open)=>{

			$open.addEventListener('click', ()=>{
				const id = $open.getAttribute('data-youtube');
				this.show(id);
			});
		});

		document.querySelector('.js-ytb-close').addEventListener('click', ()=>{
			this.hide();
		});
	}
}