import * as PIXI from "pixi.js";
import Vector from "../../util/vector";

import { isSp } from "../utils/device";

export default class Gacha {
    constructor(app, img, x, y) {
        this.isSp = true;

        if (isSp()) {
            this.isSp = true;
        } else {
            this.isSp = false;
        }

        this.app = app;
        this.img = img;

        this.position = new Vector(
            window.innerWidth / 2,
            window.innerHeight / 2
        );
        this.v = new Vector(x, y);
        this.acceleration = new Vector(0, 0.02);

        this.rotationSpeed = 0.02; // 角速度
        this.rotationAcceleration = 0.0; // 角加速度

        this.sprite = "";

        this._init();
    }

    _init() {
        this._factory();
    }

    _factory() {
        this.sprite = this._getTypeSprite();
        this.app.stage.addChild(this.sprite);

        this._animate();
    }

    _getTypeSprite() {
        let devicePixelRatio = window.devicePixelRatio || 1; //retina対応

        //ロードしたアセットをSprite表示させる
        let texture = PIXI.Texture.from(this.img);
        let sprite = new PIXI.Sprite(texture);

        sprite.scale.set(1 / devicePixelRatio); //retina対応
        
        sprite.anchor.set(0.5);
        sprite.velocity = new PIXI.Point(2.5, 2.5);

        const spritewSp = (window.innerWidth / 350) * 130;

        sprite.width = this.isSp ? spritewSp : 230;
        sprite.height = sprite.width;

        return sprite;
    }

    _resize(isSp) {
        this.isSp = isSp;

        const spritewSp = (window.innerWidth / 350) * 120;

        this.sprite.width = this.isSp ? spritewSp : 230;
        this.sprite.height = this.sprite.width;
    }

    _animate() {
        const fps = 60;
        let now = performance.now();
        let start = 0;

        let animate = (timestamp) => {
            let delta = Math.floor(timestamp - now);

            if (start === 0) {
                start = timestamp;
            }

            let elapsed = (timestamp - start) / 1000;

            while (delta >= 0) {
                this._update(elapsed);
                delta -= 1000.0 / fps;
            }

            now = timestamp;

            requestAnimationFrame(animate);
        };

        animate(performance.now());
    }

    _update(t) {
        this.position = this.position.add(this.v).add(this.acceleration);
        this._touchWall();
        this.sprite.x = this.position.x;
        this.sprite.y = this.position.y;
        //this.sprite.rotation += 0.01;

        // 角速度と角加速度を更新
        this.rotationSpeed += this.rotationAcceleration;
        this.sprite.rotation += this.rotationSpeed;
    }

    _touchWall() {
        let hit = false; // 壁に当たったかどうかのフラグ

        let headerH = "";

        if (window.innerWidth >= 1280) {
            headerH = 115;
        } else {
            headerH = 57;
        }

        const canvasH = this.isSp
            ? window.innerHeight - 57
            : window.innerHeight - headerH;

        // 左側の壁との判定
        if (this.position.x < this.sprite.width / 2) {
            this.v.x *= -1;
            this.position.x = this.sprite.width / 2;

            hit = true;
        }
        // 右側の壁との判定
        else if (this.position.x > window.innerWidth - this.sprite.width / 2) {
            this.v.x *= -1;
            this.position.x = window.innerWidth - this.sprite.width / 2;

            hit = true;
        }
        // 上側の壁との判定
        if (this.position.y < this.sprite.height / 2) {
            this.v.y *= -1;
            this.position.y = this.sprite.height / 2;

            hit = true;
        }
        // 下側の壁との判定
        else if (this.position.y > canvasH - this.sprite.height / 2) {
            this.v.y *= -1;
            this.position.y = canvasH - this.sprite.height / 2;

            hit = true;
        }

        if (hit) {
            const rotategacha = this.isSp ? 0.03 : 0.08;

            // 壁に当たった時の角速度を増加
            this.rotationSpeed = rotategacha;
        } else {
            // 壁に当たっていない時の角速度を減少
            this.rotationSpeed *= 0.99;
        }
    }

}