export default class Accordion {
    constructor() {
        this.init();
    }

    init() {
        this.eventBind();
    }

    resetEvent(reset) {
        this.eventBind(reset);
    }

    eventBind() {
        //アコーディオン
        const $triggers = [...document.querySelectorAll(".js-acc_trigger")];

        $triggers.forEach((el) => {
            el.addEventListener("click", (e) => {
                const parent = el.closest(".js-acc");
                const wrapper = el.nextElementSibling;
                const height =
                    wrapper.querySelector(".js-acc_cont").offsetHeight;

                el.classList.toggle("-active");

                if (
                    getComputedStyle(wrapper, null).getPropertyValue(
                        "height"
                    ) === "0px"
                ) {
                    wrapper.style.height = `${height}px`;

                    if (parent !== null) {
                        //高さを足していく
                        parent.style.height = `${
                            parent.offsetHeight + height
                        }px`;
                    }
                } else {
                    wrapper.style.height = 0;

                    if (parent !== null) {
                        //高さを引いていく
                        parent.style.height = `${
                            parent.offsetHeight - height
                        }px`;
                    }
                }

                // //アコーディオン完了後にページ高さが変わるのでリフレッシュ
                // setTimeout(() => {
                //     scrollfunc.refresh();
                // }, 500);
            });
        });
    }
}
