import * as PIXI from "pixi.js";

import Gacha from "./gacha.es6";
import { isSp } from "../utils/device";



export default class BgCanvas {
    constructor() {
        this.isSp = true;

        if (isSp()) {
            this.isSp = true;
        } else {
            this.isSp = false;
        }
        
        this._init();
    }

    _init() {
        this.app = null;

        this._createPixi();
    }

    _createPixi() {
        let headerH = "";

        if (window.innerWidth >= 1280) {
            headerH = 115;
        } else {
            headerH = 57;
        }

        const canvasH = this.isSp
            ? window.innerHeight - 57
            : window.innerHeight - headerH;

        let devicePixelRatio = window.devicePixelRatio || 1; //retina対応

        this.app = new PIXI.Application({
            width: window.innerWidth,
            height: canvasH,
            backgroundColor: 0x000000,
            backgroundAlpha: 0,
            view: document.querySelector(".js-canvas"),
            transparent: true,
            resolution: devicePixelRatio, //retina対応
            autoDensity: true, //retina対応
        });

        this._loadImg();
    }

    _loadImg() {
        const logoimg = "/2023/assets/img/common/bg/ph_gacha.png";

        const texPromise = PIXI.Assets.load(logoimg);

        const Vectorx = this.isSp ? 1.5 : 4;
        const Vectory = this.isSp ? 1.5 : 4;

        texPromise.then(() => {
            this.gacha = new Gacha(this.app, logoimg, Vectorx, Vectory);

            this._resize();
        });
    }

    _resize() {
        window.addEventListener("resize", () => {
            let headerH = "";

            if (window.innerWidth >= 1280) {
                headerH = 115;
            } else {
                headerH = 57;
            }

            if (isSp()) {
                this.isSp = true;
            } else {
                this.isSp = false;
            }

            const canvasH = this.isSp
                ? window.innerHeight - 57
                : window.innerHeight - headerH;

            this.app.renderer.resize(window.innerWidth, canvasH);

            

            if (this.gacha) {
                this.gacha._resize(this.isSp);
            }
        });
    }
}
