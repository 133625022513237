import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);


export default class Switch {
    constructor() {
        this.plink = false
        this._init();
    }

    _init() {
        const switchbtn = document.querySelectorAll(".js-switch");

        if (switchbtn !== null) {
            this._switchCont();
            this._stigerBtn();
        }
    }

    _switchCont() {
        const switchbtn = [...document.querySelectorAll(".js-switch__btn")];

        switchbtn.forEach((el) => {
            el.addEventListener("click", () => {
                const kvId = el.getAttribute("data-switch");
                const currentKv = document.body.getAttribute("data-type");

                if (kvId !== currentKv) {
                    document.body.setAttribute("data-type", kvId);
                } else {
                    return;
                }
            });
        });
    }

    _stigerBtn() {
        const triggerElmLink = document.querySelector(".c-pastlink");
        const triggerElmFooter = document.querySelector(".l-footer");

        if (triggerElmLink !== null) {
            this._hideBtn(triggerElmLink);
        } else {
            this._hideBtn(triggerElmFooter);
        }
    }

    _hideBtn(triggrt) {
        const triggerElm = triggrt;

        ScrollTrigger.create({
            trigger: triggerElm,
            start: "top bottom",
            end: "bottom bottom",

            onLeave: () => {
                document.body.classList.add("-hidebtn");
            },
            onEnterBack: () => {
                document.body.classList.remove("-hidebtn");
            },
            once: false,
            //markers: true,
        });
    }
}
