export default class Header{

    constructor(){
        this._init();
    }


    _init(){
        this.BREAK_HD = 'only screen and (min-width: 1281px)';
        this.$header = document.querySelector('.l-header');
        this.$navOpen = document.querySelector('.js-header__open');
        this._setGnav();
    }

    _setGnav(){

        // ブレイクポイントによるハンバーガーメニューの出し分け
        if(window.matchMedia){
            this._checkNavBreak();
            window
                .matchMedia(this.BREAK_HD)
                .addEventListener("change", this._checkNavBreak.bind(this));
        }

        // ハンバーガーメニューのトリガー
        this.$navOpen.addEventListener('click', () => {
            if(this.$header.classList.contains('-navopen')) this._hidenGNav();
            else this._showGNav();
        });
    }

    _showGNav() {
        this.$header.classList.add('-navopen');
    }

    _hidenGNav() {
        this.$header.classList.remove('-navopen');
    }

    _checkNavBreak() {

        if(window.matchMedia(this.BREAK_HD).matches){
            this._hidenGNav();
        }
    }
}