/**
 * スムーススクロールによるアンカーリンク
 */
import { gsap } from "gsap";

export default class Anchor {
    constructor() {
        this._init();
    }

    _init() {
        const links = [...document.querySelectorAll("a.js-smooth")];

        links.forEach((el) => {
            el.addEventListener("click", (e) => {
                e.preventDefault();
                const $target = document.querySelector(el.getAttribute("href"));
                if (!$target) return false;

                const adjust = parseInt($target.getAttribute("data-adjust"));
                const targetAdjust = adjust ? adjust : 0;
                const headerHeight = window.innerWidth <= 768 ? 64 : 115;
                let targetPos =
                    parseInt(this._getAbsoluteTop($target)) -
                    targetAdjust -
                    headerHeight;
                targetPos = targetPos < 0 ? 0 : targetPos;

                gsap.to(
                    [
                        document.querySelector("html"),
                        document.querySelector("body"),
                    ],
                    { duration: 0.7, scrollTop: targetPos, ease: "power2.out" }
                );
            });
        });

        
    }

    _getAbsoluteTop(_selector) {
        return _selector.getBoundingClientRect().top + window.pageYOffset;
    }
}
