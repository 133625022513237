let isSpFlag = false;

// MediaQueryManager クラス
class MediaQueryManager {
    constructor() {
        this._setBp();
    }

    _setBp() {
        const mediaQuery = window.matchMedia("(max-width: 768px)");

        mediaQuery.addListener((e) => {
            this._handleTabletChange(e);
        });

        this._handleTabletChange(mediaQuery);
    }

    _handleTabletChange(e) {
        if (e.matches) {
            isSpFlag = true;
        } else {
            isSpFlag = false;
        }
    }
}

// インスタンスを作成
const mediaQueryManager = new MediaQueryManager();

// isSpメソッドとisPcメソッドをエクスポート
export function isSp() {
    return isSpFlag;
}
